<template>
    <div class="accordion-item" @click="toggleAccordion()">
        <div class="accordion-header collapsed" type="button" data-bs-toggle="collapse"
             :data-bs-target="`#flush-collapse-${number}`" aria-expanded="false"
             :aria-controls="`flush-collapse-${number}`">
            <div class="row align-items-center">
                <div class="col-lg-2 d-lg-block d-none">
                    <h4 class="accordion-text numbering mb-0">// {{ number.toString().padStart(2, '0') }} \\</h4>
                </div>
                <div class="col-lg-9 col-md-11 col-10">
                    <h4 class="accordion-text main mb-0">{{ title }}</h4>
                </div>
                <div class="col-md-1 col-2 text-end">
                    <img src="../assets/icons/right-arrow.svg"
                         :class="`accordion-arrow ${isOpen ? 'rotate-90' : 'rotate-0'}`"
                         alt="Right Arrow">
                </div>
            </div>
        </div>
        <div :id="`flush-collapse-${number}`" class="accordion-collapse collapse">
            <div class="accordion-body">
                <div class="row">
                    <div :class="`${!image ? 'col-lg-8' : 'col-lg-4'} offset-lg-2 p-0`">
                        <p class="accordion-desc" v-html="description"></p>
                    </div>
                    <div class="col-lg-6" v-if="image">
                        <img :src="require(`@/assets/images/${image}`)" :alt="image"
                             class="accordion-inside-img w-100">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesAccordion',
    props: {
        number: Number,
        title: String,
        image: String,
        description: String,
    },
    data() {
        return {
            isOpen: false,
        };
    },

    methods: {
        toggleAccordion() {
            this.isOpen = !this.isOpen;
        },
    },
};
</script>

<style scoped>
.accordion-item {
    border-bottom: 3px solid #000000 !important;
}

.accordion-item .accordion-header {
    padding: 40px 0;
}

.accordion-item .accordion-arrow {
    transition: all 0.5s ease-in-out;
}

.accordion-item .accordion-header .accordion-text.numbering {
    margin-left: 1%;
}

@media only screen and (max-width: 991px) {
    .accordion-item .accordion-collapse .accordion-body .accordion-inside-img {
        margin: 30px auto 15px;
    }
}

@media only screen and (max-width: 576px) {
    .accordion-item .accordion-arrow {
        width: 30px;
    }
}
</style>