<template>
    <div>
        <div class="header-container services">
            <div class="container">
                <div class="row header-wrapper">
                    <div class="content-placeholder text-center">
                        <div class="content col-xxl-7 col-lg-9 m-auto">
                            <h1 class="heading-text mb-4"
                                data-aos="fade-up"
                                data-aos-duration="700"
                                data-aos-once="true"
                                data-aos-easing="ease-out-quart">
                                {{ this.trans("PAGES.SERVICES.HEADER.TITLE_BEFORE_SPAN") }} <span
                                    class="text-brown font-black">{{
                                    this.trans("PAGES.SERVICES.HEADER.TITLE_SPAN")
                                }}</span> {{ this.trans("PAGES.SERVICES.HEADER.TITLE_AFTER_SPAN") }}
                            </h1>
                            <p class="heading-description-text"
                               data-aos="fade-up"
                               data-aos-duration="700"
                               data-aos-delay="500"
                               data-aos-once="true"
                               data-aos-easing="ease-out-quart">
                                {{ this.trans("PAGES.SERVICES.HEADER.DESCRIPTION") }}
                            </p>
                        </div>
                        <img src="../assets/images/services-heading.png" class="heading-main-img mt-5 pt-2" alt=""
                             data-aos="zoom-in"
                             data-aos-duration="700"
                             data-aos-delay="1000"
                             data-aos-once="true"
                             data-aos-easing="ease-out-quart">
                    </div>
                </div>
            </div>
        </div>
        <div class="heading-gradient services"></div>
        <main class="page services">
            <section class="services-list">
                <div class="container">
                    <div class="row">
                        <div class="accordion accordion-flush">
                            <ServicesAccordion
                                    v-for="(item, index) in items"
                                    :title="item.title"
                                    :number="index+1"
                                    :image="item.image"
                                    :description="item.description"
                                    v-bind:key="index"
                                    data-aos="fade-up"
                                    data-aos-duration="600"
                                    data-aos-delay="1000"
                                    data-aos-once="true"
                                    data-aos-easing="ease-out-quart"/>
                        </div>
                    </div>
                </div>
            </section>
            <section class="image-section">
                <div class="container">
                    <div class="row">
                        <img src="../assets/images/last-img-services.png" alt="" class="big-image w-75 m-auto"
                             data-aos="zoom-in-up"
                             data-aos-offset="500"
                             data-aos-once="true"
                             data-aos-easing="ease-out-quart">
                    </div>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import ServicesAccordion from "@/components/ServicesAccordion";
import $ from "jquery";

export default {
    name: "Services",
    components: {
        ServicesAccordion
    },
    data: function () {
        return {
            items: [
                {
                    title: this.trans("PAGES.SERVICES.LIST.ITEM_1.TITLE"),
                    description: this.trans("PAGES.SERVICES.LIST.ITEM_1.DESCRIPTION"),
                },
                {
                    title: this.trans("PAGES.SERVICES.LIST.ITEM_2.TITLE"),
                    description: this.trans("PAGES.SERVICES.LIST.ITEM_2.DESCRIPTION"),
                },
                {
                    title: this.trans("PAGES.SERVICES.LIST.ITEM_3.TITLE"),
                    description: this.trans("PAGES.SERVICES.LIST.ITEM_3.DESCRIPTION"),
                },
                {
                    title: this.trans("PAGES.SERVICES.LIST.ITEM_4.TITLE"),
                    description: this.trans("PAGES.SERVICES.LIST.ITEM_4.DESCRIPTION"),
                },
                {
                    title: this.trans("PAGES.SERVICES.LIST.ITEM_5.TITLE"),
                    description: this.trans("PAGES.SERVICES.LIST.ITEM_5.DESCRIPTION"),
                },
                {
                    title: this.trans("PAGES.SERVICES.LIST.ITEM_6.TITLE"),
                    description: this.trans("PAGES.SERVICES.LIST.ITEM_6.DESCRIPTION"),
                },
                {
                    title: this.trans("PAGES.SERVICES.LIST.ITEM_7.TITLE"),
                    description: this.trans("PAGES.SERVICES.LIST.ITEM_7.DESCRIPTION"),
                },
            ]
        }
    },
    mounted() {
        $(".lang-link").removeClass("router-link-active");
        $(".lang-link." + localStorage.selectedLanguage).addClass("router-link-active");
        this.$root.setTitle(this.trans('NAV_ITEMS.SERVICES'))
        window.scrollTo(0, 0)
        document.getElementById('push-button').classList.add('white')
    },
    unmounted() {
        document.getElementById('push-button').classList.remove('white')
    }
}
</script>